import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosConfig";
import {
    TextField,
    Button,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

const NodePricing = () => {
    const [nodes, setNodes] = useState([]);
    const [prices, setPrices] = useState({});
    const [stats, setStats] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        const fetchNodeNames = async () => {
            try {
                const response = await axiosInstance.get("/admin/node-names");
                setNodes(response.data);
                const initialPrices = response.data.reduce((acc, node) => {
                    acc[node.name] = node.server_cost;
                    return acc;
                }, {});
                setPrices(initialPrices);
            } catch (error) {
                console.error("Error fetching node names:", error);
            }
        };
        fetchNodeNames();
    }, []);

    const updateServerCost = async (nodeId, newServerCost) => {
        try {
            await axiosInstance.put("/admin/update-server-cost", {
                nodeId,
                newServerCost,
            });
        } catch (error) {
            console.error("Error updating server cost:", error);
        }
    };

    const handlePriceChange = (nodeName, value) => {
        const newServerCost = parseFloat(value);
        setPrices((prevPrices) => ({
            ...prevPrices,
            [nodeName]: newServerCost,
        }));
        const nodeId = nodes.find((node) => node.name === nodeName)._id;
        updateServerCost(nodeId, newServerCost);
    };

    const fetchStats = async () => {
        try {
            const response = await axiosInstance.get("/admin/node-stats", {
                params: { startDate, endDate },
            });
            setStats(response.data);
        } catch (error) {
            console.error("Error fetching node stats:", error);
        }
    };

    const fetchTotal = async () => {
        try {
            const response = await axiosInstance.get(
                "/admin/invoices-full-sum",
                {
                    params: { startDate, endDate },
                }
            );
            console.log("total", response);
        } catch (error) {
            console.error("Error fetching node stats:", error);
        }
    };

    const fetchFarcasters = async () => {
        try {
            const response = await axiosInstance.get(
                "/admin/invoices-with-farcaster",
                {
                    params: { startDate, endDate },
                }
            );
            console.log(response);
        } catch (error) {
            console.error("Error fetching node stats:", error);
        }
    };

    const generatePDF = () => {
        const doc = new jsPDF("landscape");

        // Добавляем текст с датами отчета и ценами за сервер
        doc.setFontSize(12);
        doc.text(
            `Report: ${moment(startDate).format("DD.MM.YYYY")} - ${moment(
                endDate
            ).format("DD.MM.YYYY")}`,
            14,
            20
        );
        nodes.forEach((node, index) => {
            doc.text(
                `${node.name} server cost: ${prices[node.name] || ""}$`,
                14,
                30 + index * 10
            );
        });

        // Добавляем таблицу с использованием autoTable
        const columns = [
            "Node Name",
            "Price Type",
            "Price",
            "Months / Amount",
            "Revenue",
            "Net Profit",
            "Node Revenue",
            "Node Net Profit",
        ];
        const rows = [];

        const totalAmount = Object.keys(stats).reduce((total, nodeName) => {
            const nodeStats = stats[nodeName];
            const monthlyTotal = Object.keys(nodeStats.monthly).reduce(
                (subTotal, price) =>
                    subTotal + (nodeStats.monthly[price].total / 100) * 98,
                0
            );
            const installationTotal = Object.keys(
                nodeStats.installation
            ).reduce(
                (subTotal, price) =>
                    subTotal + (nodeStats.installation[price].total / 100) * 98,
                0
            );
            return total + monthlyTotal + installationTotal;
        }, 0);

        const totalNet = Object.keys(stats).reduce((total, nodeName) => {
            const nodeStats = stats[nodeName];
            const monthlyTotal = Object.keys(nodeStats.monthly).reduce(
                (subTotal, price) =>
                    subTotal +
                    ((nodeStats.monthly[price].total / 100) * 98 -
                        (prices[nodeName] || 0) *
                            nodeStats.monthly[price].months),
                0
            );
            const installationTotal = Object.keys(
                nodeStats.installation
            ).reduce(
                (subTotal, price) =>
                    subTotal + (nodeStats.installation[price].total / 100) * 98,
                0
            );
            return total + monthlyTotal + installationTotal;
        }, 0);

        Object.keys(stats).forEach((nodeName) => {
            const nodeStats = stats[nodeName];
            const nodeTotal = (
                Object.keys(nodeStats.monthly).reduce(
                    (subTotal, price) =>
                        subTotal + nodeStats.monthly[price].total,
                    0
                ) +
                Object.keys(nodeStats.installation).reduce(
                    (subTotal, price) =>
                        subTotal + nodeStats.installation[price].total,
                    0
                )
            ).toFixed(2);
            const nodeNet = (
                Object.keys(nodeStats.monthly).reduce(
                    (subTotal, price) =>
                        subTotal +
                        (nodeStats.monthly[price].total -
                            (prices[nodeName] || 0) *
                                nodeStats.monthly[price].months),
                    0
                ) +
                Object.keys(nodeStats.installation).reduce(
                    (subTotal, price) =>
                        subTotal + nodeStats.installation[price].total,
                    0
                )
            ).toFixed(2);

            Object.keys(nodeStats.monthly).forEach((price, index) => {
                rows.push([
                    index === 0 ? nodeName : "",
                    "Monthly",
                    parseFloat(price).toFixed(2) + "$",
                    nodeStats.monthly[price].months,
                    nodeStats.monthly[price].total.toFixed(2) + "$",
                    (
                        nodeStats.monthly[price].total -
                        (prices[nodeName] || 0) *
                            nodeStats.monthly[price].months
                    ).toFixed(2) + "$",
                    index === 0 ? nodeTotal + "$" : "",
                    index === 0 ? nodeNet + "$" : "",
                ]);
            });

            Object.keys(nodeStats.installation).forEach((price, index) => {
                rows.push([
                    "",
                    "Installation",
                    parseFloat(price).toFixed(2) + "$",
                    nodeStats.installation[price].amount,
                    nodeStats.installation[price].total.toFixed(2) + "$",
                    nodeStats.installation[price].total.toFixed(2) + "$",
                    "",
                    "",
                ]);
            });
        });

        rows.push([
            "",
            "",
            "",
            "",
            "Overall Revenue",
            totalAmount.toFixed(2) + "$",
            "",
            "",
        ]);
        rows.push([
            "",
            "",
            "",
            "",
            "Overall Net Profit",
            totalNet.toFixed(2) + "$",
            "",
            "",
        ]);

        doc.autoTable({
            head: [columns],
            body: rows,
            startY: 30 + nodes.length * 10,
            theme: "grid",
            styles: { fontSize: 8 },
            headStyles: { fillColor: [22, 160, 133] },
            alternateRowStyles: { fillColor: [238, 238, 238] },
            margin: { top: 20 },
        });

        doc.save("report.pdf");
    };

    const renderStats = () => {
        if (!stats) return null;

        const totalAmount = Object.keys(stats).reduce((total, nodeName) => {
            const nodeStats = stats[nodeName];
            const monthlyTotal = Object.keys(nodeStats.monthly).reduce(
                (subTotal, price) =>
                    subTotal + (nodeStats.monthly[price].total / 100) * 98,
                0
            );
            const installationTotal = Object.keys(
                nodeStats.installation
            ).reduce(
                (subTotal, price) =>
                    subTotal + (nodeStats.installation[price].total / 100) * 98,
                0
            );
            return total + monthlyTotal + installationTotal;
        }, 0);

        const totalNet = Object.keys(stats).reduce((total, nodeName) => {
            const nodeStats = stats[nodeName];
            const monthlyTotal = Object.keys(nodeStats.monthly).reduce(
                (subTotal, price) =>
                    subTotal +
                    ((nodeStats.monthly[price].total / 100) * 98 -
                        (prices[nodeName] || 0) *
                            nodeStats.monthly[price].months),
                0
            );
            const installationTotal = Object.keys(
                nodeStats.installation
            ).reduce(
                (subTotal, price) =>
                    subTotal + (nodeStats.installation[price].total / 100) * 98,
                0
            );
            return total + monthlyTotal + installationTotal;
        }, 0);

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Node Name</TableCell>
                            <TableCell>Price Type</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Months / Amount</TableCell>
                            <TableCell>Revenue</TableCell>
                            <TableCell>Net Profit</TableCell>
                            <TableCell>Node Revenue</TableCell>
                            <TableCell>Node Net Profit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(stats).map((nodeName) => {
                            const nodeStats = stats[nodeName];
                            const nodeTotal = (
                                Object.keys(nodeStats.monthly).reduce(
                                    (subTotal, price) =>
                                        subTotal +
                                        nodeStats.monthly[price].total,
                                    0
                                ) +
                                Object.keys(nodeStats.installation).reduce(
                                    (subTotal, price) =>
                                        subTotal +
                                        nodeStats.installation[price].total,
                                    0
                                )
                            ).toFixed(2);
                            const nodeNet = (
                                Object.keys(nodeStats.monthly).reduce(
                                    (subTotal, price) =>
                                        subTotal +
                                        (nodeStats.monthly[price].total -
                                            (prices[nodeName] || 0) *
                                                nodeStats.monthly[price]
                                                    .months),
                                    0
                                ) +
                                Object.keys(nodeStats.installation).reduce(
                                    (subTotal, price) =>
                                        subTotal +
                                        nodeStats.installation[price].total,
                                    0
                                )
                            ).toFixed(2);
                            return (
                                <React.Fragment key={nodeName}>
                                    {Object.keys(nodeStats.monthly).map(
                                        (price, index) => (
                                            <TableRow
                                                key={`${nodeName}-monthly-${price}`}
                                            >
                                                {index === 0 && (
                                                    <TableCell
                                                        rowSpan={
                                                            Object.keys(
                                                                nodeStats.monthly
                                                            ).length +
                                                            Object.keys(
                                                                nodeStats.installation
                                                            ).length
                                                        }
                                                    >
                                                        {nodeName}
                                                    </TableCell>
                                                )}
                                                <TableCell>Monthly</TableCell>
                                                <TableCell>
                                                    {parseFloat(price).toFixed(
                                                        2
                                                    )}
                                                    $
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        nodeStats.monthly[price]
                                                            .months
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {nodeStats.monthly[
                                                        price
                                                    ].total.toFixed(2)}
                                                    $
                                                </TableCell>
                                                <TableCell>
                                                    {(
                                                        nodeStats.monthly[price]
                                                            .total -
                                                        (prices[nodeName] ||
                                                            0) *
                                                            nodeStats.monthly[
                                                                price
                                                            ].months
                                                    ).toFixed(2)}
                                                    $
                                                </TableCell>
                                                {index === 0 && (
                                                    <TableCell
                                                        rowSpan={
                                                            Object.keys(
                                                                nodeStats.monthly
                                                            ).length +
                                                            Object.keys(
                                                                nodeStats.installation
                                                            ).length
                                                        }
                                                    >
                                                        {nodeTotal}$
                                                    </TableCell>
                                                )}
                                                {index === 0 && (
                                                    <TableCell
                                                        rowSpan={
                                                            Object.keys(
                                                                nodeStats.monthly
                                                            ).length +
                                                            Object.keys(
                                                                nodeStats.installation
                                                            ).length
                                                        }
                                                    >
                                                        {nodeNet}$
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        )
                                    )}
                                    {Object.keys(nodeStats.installation).map(
                                        (price, index) => (
                                            <TableRow
                                                key={`${nodeName}-installation-${price}`}
                                            >
                                                <TableCell>
                                                    Installation
                                                </TableCell>
                                                <TableCell>
                                                    {parseFloat(price).toFixed(
                                                        2
                                                    )}
                                                    $
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        nodeStats.installation[
                                                            price
                                                        ].amount
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {nodeStats.installation[
                                                        price
                                                    ].total.toFixed(2)}
                                                    $
                                                </TableCell>
                                                <TableCell>
                                                    {nodeStats.installation[
                                                        price
                                                    ].total.toFixed(2)}
                                                    $
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </React.Fragment>
                            );
                        })}
                        <TableRow>
                            <TableCell colSpan={5} sx={{ fontWeight: "700" }}>
                                Overall Revenue
                            </TableCell>
                            <TableCell sx={{ fontWeight: "700" }}>
                                {totalAmount.toFixed(2)}$
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={5} sx={{ fontWeight: "700" }}>
                                Overall Net Profit
                            </TableCell>
                            <TableCell sx={{ fontWeight: "700" }}>
                                {totalNet.toFixed(2)}$
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Box>
            <Typography variant="h4" mb={3}>
                Node Pricing
            </Typography>
            <Box mb={3} sx={{ display: "flex", flexWrap: "wrap" }}>
                {nodes.map((node) => (
                    <Box key={node._id} mb={2}>
                        <Typography variant="h6">{node.name}</Typography>
                        <TextField
                            label="Server Cost per Month"
                            type="number"
                            value={prices[node.name] || ""}
                            onChange={(e) =>
                                handlePriceChange(node.name, e.target.value)
                            }
                            InputLabelProps={{ shrink: true }}
                            sx={{ marginRight: 2 }}
                        />
                    </Box>
                ))}
            </Box>
            <Box mb={3} sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ marginRight: 2 }}
                />
                <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ marginRight: 2 }}
                />
                <Button variant="contained" onClick={fetchStats}>
                    Fetch Stats
                </Button>
                <Button variant="contained" onClick={fetchTotal}>
                    Fetch Total
                </Button>
                <Button variant="contained" onClick={fetchFarcasters}>
                    Fetch Farcasters
                </Button>
            </Box>

            <Box id="pdf-content">
                <Typography
                    sx={{
                        width: "100%",
                        textAlign: "center",
                        marginBottom: "30px",
                        fontSize: "24px",
                        fontWeight: "700",
                    }}
                >
                    Report {moment(startDate).format("DD.MM.YYYY")} -{" "}
                    {moment(endDate).format("DD.MM.YYYY")}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        gap: "2rem",
                        marginBottom: "20px",
                        justifyContent: "center",
                    }}
                >
                    {nodes.map((node) => (
                        <Typography key={node._id} sx={{ fontWeight: "600" }}>
                            {node.name} expenses: {prices[node.name] || ""}$
                        </Typography>
                    ))}
                </Box>
                {renderStats()}
                <Typography sx={{ marginTop: "20px" }}>
                    *The price for one node may vary, since a node can be
                    purchased using a promotional code.
                </Typography>
            </Box>
            <Button
                variant="contained"
                onClick={generatePDF}
                sx={{ marginTop: "50px" }}
            >
                Generate PDF
            </Button>
        </Box>
    );
};

export default NodePricing;
