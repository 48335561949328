import React, { useEffect, useState } from "react";
import {
    Container,
    Grid,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import axiosInstance from "../utils/axiosConfig";

const Dashboard = () => {
    const [data, setData] = useState({
        totalNodes: 0,
        activeNodes: 0,
        pendingNodes: 0,
        expiredNodes: 0,
        endingThisMonthNodes: 0,
        endingAfterThisMonthNodes: 0,
        totalUsers: 0,
        usersWithNodes: 0,
        usersWithActiveNodes: 0,
        usersWith3OrMoreActiveNodes: 0,
        nodeStats: [],
        promoStats: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get("/admin/statsNew");
                setData(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const paperStyle = {
        padding: "20px",
        textAlign: "center",
        margin: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    };

    const formatNumber = (num) => {
        return num.toFixed(2);
    };

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Paper style={paperStyle}>
                        <Typography variant="h6">Всего нод</Typography>
                        <Typography variant="h4">{data.totalNodes}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={paperStyle}>
                        <Typography variant="h6">Активных нод</Typography>
                        <Typography variant="h4">{data.activeNodes}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={paperStyle}>
                        <Typography variant="h6">Pending нод</Typography>
                        <Typography variant="h4">
                            {data.pendingNodes}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={paperStyle}>
                        <Typography variant="h6">Expired нод</Typography>
                        <Typography variant="h4">
                            {data.expiredNodes}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={paperStyle}>
                        <Typography variant="h6">
                            Нод заканчиваются в этом месяце
                        </Typography>
                        <Typography variant="h4">
                            {data.endingThisMonthNodes}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={paperStyle}>
                        <Typography variant="h6">
                            Нод заканчиваются позже этого месяца
                        </Typography>
                        <Typography variant="h4">
                            {data.endingAfterThisMonthNodes}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={paperStyle}>
                        <Typography variant="h6">
                            Всего пользователей
                        </Typography>
                        <Typography variant="h4">{data.totalUsers}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={paperStyle}>
                        <Typography variant="h6">
                            Пользователей с нодами
                        </Typography>
                        <Typography variant="h4">
                            {data.usersWithNodes}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={paperStyle}>
                        <Typography variant="h6">
                            Пользователей с активными нодами
                        </Typography>
                        <Typography variant="h4">
                            {data.usersWithActiveNodes}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper style={paperStyle}>
                        <Typography variant="h6">
                            Пользователей с 3 и более активными нодами
                        </Typography>
                        <Typography variant="h4">
                            {data.usersWith3OrMoreActiveNodes}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Paper style={{ padding: "20px", margin: "10px" }}>
                        <Typography
                            variant="h6"
                            style={{ textAlign: "center" }}
                        >
                            Статистика по нодам
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Имя ноды</TableCell>
                                        <TableCell>Всего</TableCell>
                                        <TableCell>Активных</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.nodeStats.map((node) => (
                                        <TableRow key={node._id}>
                                            <TableCell>{node._id}</TableCell>
                                            <TableCell>{node.total}</TableCell>
                                            <TableCell>{node.active}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper style={{ padding: "20px", margin: "10px" }}>
                        <Typography
                            variant="h6"
                            style={{ textAlign: "center" }}
                        >
                            Статистика по промокодам
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Промокод</TableCell>
                                        <TableCell>Использований</TableCell>
                                        <TableCell>
                                            Денег с промо всего
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.promoStats.map((promo) => (
                                        <TableRow key={promo.promoCode}>
                                            <TableCell>
                                                {promo.promoCode}
                                            </TableCell>
                                            <TableCell>
                                                {promo.usage_count}
                                            </TableCell>
                                            <TableCell>
                                                {formatNumber(
                                                    promo.totalAmount
                                                )}
                                                $
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default Dashboard;
