import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Pagination,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfluencerMonthlyStats from "./InfluencersMonthlyStats";

const InfluencersTable = ({ userId }) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [selectedInfluencer, setSelectedInfluencer] = useState(null);
    const limit = 10;

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(
                `/admin/influencers/${userId}`,
                {
                    params: {
                        page,
                        limit,
                        select: "email tg influencerBonusPercent _id createdAt",
                    },
                }
            );
            setData(response.data.referredInfluencers);
            setTotal(response.data.total);
        } catch (error) {
            console.error("Error fetching influencers:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page]);

    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h2 className="text-lg font-semibold">Referred Influencers</h2>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>User</TableCell>
                                <TableCell>Bonus %</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((influencer) => (
                                <TableRow key={influencer._id}>
                                    <TableCell>
                                        {influencer.email ||
                                            influencer.tg ||
                                            "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {influencer.influencerBonusPercent}%
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="outlined"
                                            onClick={() =>
                                                setSelectedInfluencer(
                                                    influencer
                                                )
                                            }
                                        >
                                            View Stats
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Pagination
                    count={Math.ceil(total / limit)}
                    page={page}
                    onChange={(e, value) => setPage(value)}
                    sx={{ mt: 2 }}
                />

                {selectedInfluencer && (
                    <Dialog
                        open={!!selectedInfluencer}
                        onClose={() => setSelectedInfluencer(null)}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle>
                            Stats for{" "}
                            {selectedInfluencer.email || selectedInfluencer.tg}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContent>
                                <InfluencerMonthlyStats
                                    userId={selectedInfluencer._id}
                                />
                            </DialogContent>
                        </DialogContent>
                    </Dialog>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default InfluencersTable;
