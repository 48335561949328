import React, { useState } from "react";
import Web3 from "web3";
import {
    TextField,
    Button,
    Container,
    Typography,
    TextareaAutosize,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress,
    LinearProgress,
    Box,
} from "@mui/material";

const FaucetForm = () => {
    const [network, setNetwork] = useState("holesky");
    const [amount, setAmount] = useState("0.0011");
    const [wallets, setWallets] = useState("");
    const [transactionCount, setTransactionCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [notification, setNotification] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setProgress(0);
        setTransactionCount(0); // Сбросить счетчик транзакций
        const privateKey =
            "0x9bb8d5179cba654dabe888e3311a7bfd505f2f3a25fbdfeae3fea404293e34e0";
        const fromAddress = "0x67607B775D6aDbA433CC0D09b0957b2273F4E762";
        const urls = {
            holesky: "https://ethereum-holesky-rpc.publicnode.com",
            sepolia: "https://ethereum-sepolia-rpc.publicnode.com",
        };
        const web3 = new Web3(urls[network]);
        const account = web3.eth.accounts.privateKeyToAccount(privateKey);
        web3.eth.accounts.wallet.add(account);
        const amountInWei = web3.utils.toWei(amount, "ether");
        const gasPrice = await web3.eth.getGasPrice();
        const signedTransactions = [];
        let nonce = await web3.eth.getTransactionCount(fromAddress, "pending");
        const walletsArray = wallets
            .split("\n")
            .map((w) => w.trim())
            .filter(Boolean);

        for (let wallet of walletsArray) {
            if (web3.utils.isAddress(wallet)) {
                try {
                    const tx = {
                        from: fromAddress,
                        to: wallet,
                        value: amountInWei,
                        gas: 22000,
                        gasPrice: gasPrice,
                        nonce: nonce++,
                    };
                    const signedTx = await web3.eth.accounts.signTransaction(
                        tx,
                        privateKey
                    );
                    signedTransactions.push(signedTx.rawTransaction);
                } catch (error) {
                    console.error(
                        `Ошибка при подписании транзакции для ${wallet}:`,
                        error
                    );
                }
            } else {
                console.error(`Неверный адрес: ${wallet}`);
            }
        }

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        try {
            for (let i = 0; i < signedTransactions.length; i++) {
                const rawTx = signedTransactions[i];
                web3.eth
                    .sendSignedTransaction(rawTx)
                    .on("receipt", (receipt) => {
                        console.log("Транзакция подтверждена:", receipt);
                        setTransactionCount((prevCount) => prevCount + 1);
                    })
                    .on("error", (error) => {
                        console.error("Ошибка при отправке транзакции:", error);
                    });
                await delay(2000);
                setProgress(((i + 1) / signedTransactions.length) * 100);
            }
            setNotification("Транзакции успешно отправлены!");
        } catch (error) {
            console.error("Ошибка при отправке транзакций:", error);
        }
        setLoading(false);
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Ethereum Faucet
            </Typography>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="network-label">Select Network</InputLabel>
                    <Select
                        labelId="network-label"
                        id="network"
                        value={network}
                        onChange={(e) => setNetwork(e.target.value)}
                    >
                        <MenuItem value="holesky">Holesky</MenuItem>
                        <MenuItem value="sepolia">Sepolia</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="Enter ETH amount to send"
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextareaAutosize
                    minRows={10}
                    placeholder="Wallets (1 line = 1 wallet. Max 100)"
                    value={wallets}
                    onChange={(e) => setWallets(e.target.value)}
                    style={{ width: "100%", marginBottom: "20px" }}
                />
                <Typography variant="body1" gutterBottom>
                    Транзакций отправлено: {transactionCount}
                </Typography>
                <Box
                    sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                    {loading && <CircularProgress size={24} />}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        style={{ marginLeft: 16 }}
                    >
                        {loading ? "Sending..." : "Send ETH"}
                    </Button>
                </Box>
                {loading && (
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        style={{ marginTop: 16 }}
                    />
                )}
            </form>
            {notification && (
                <Typography
                    variant="body1"
                    style={{ marginTop: 16, color: "green" }}
                >
                    {notification}
                </Typography>
            )}
        </Container>
    );
};

export default FaucetForm;
