import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Pagination,
    PaginationItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const MonthlyStatsTable = ({ userId }) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const limit = 10;

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(
                `/admin/monthly-stats/${userId}`,
                {
                    params: { page, limit },
                }
            );
            const monthlyStats = Object.keys(response.data.monthlyStats).map(
                (key) => {
                    const [year, month] = key.split("-");
                    return {
                        month: `${month}/${year}`,
                        ...response.data.monthlyStats[key],
                    };
                }
            );
            setData(monthlyStats);
            setTotalPages(Math.ceil(monthlyStats.length / limit));
        } catch (error) {
            console.error("Error fetching monthly stats:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page]);

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h2 className="text-lg font-semibold">Monthly Stats</h2>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Month</TableCell>
                                <TableCell>Monthly Amount</TableCell>
                                <TableCell>Monthly Paid Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length ? (
                                data.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.month}</TableCell>
                                        <TableCell>
                                            ${row.monthlyAmount.toFixed(2)}
                                        </TableCell>
                                        <TableCell>
                                            ${row.monthlyPaidAmount.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={3}
                                        className="h-24 text-center"
                                    >
                                        You don't have a profit yet.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {totalPages > 1 && (
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                        renderItem={(item) => (
                            <PaginationItem
                                {...item}
                                disabled={item.page === page}
                            />
                        )}
                    />
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default MonthlyStatsTable;
