import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    Button,
} from "@mui/material";

const ExpiredNodes = () => {
    const [nodes, setNodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchExpiredNodes = async () => {
        try {
            const response = await axiosInstance.get("/admin/expired-nodes");
            setNodes(response.data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchExpiredNodes();
    }, []);

    const handleUnlink = async (nodeId) => {
        try {
            await axiosInstance.put(`/admin/unlink-node/${nodeId}`);
            fetchExpiredNodes(); // Обновить список после unlink
        } catch (err) {
            console.error("Error unlinking node:", err);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">Ошибка: {error.message}</Typography>;
    }

    return (
        <div>
            <Typography sx={{ marginBottom: "20px", fontWeight: "700" }}>
                Expired User Nodes
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Имя</TableCell>
                            <TableCell>Изображение</TableCell>
                            <TableCell>Email или TG пользователя</TableCell>
                            <TableCell>ID пользователя</TableCell>
                            <TableCell>Дата установки</TableCell>
                            <TableCell>Дата окончания</TableCell>
                            <TableCell>Тип деплоя</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nodes.map((node) => (
                            <TableRow key={node._id}>
                                <TableCell>{node._id}</TableCell>
                                <TableCell>{node.name}</TableCell>
                                <TableCell>
                                    <img
                                        src={node.image}
                                        alt={node.name}
                                        width="50"
                                    />
                                </TableCell>
                                <TableCell>
                                    {node.user.email || `@${node.user.tg}`}
                                </TableCell>
                                <TableCell>{node.user._id}</TableCell>
                                <TableCell>
                                    {new Date(node.date).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    {new Date(
                                        node.endDate
                                    ).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    {node.deployType === "keys"
                                        ? "С ключами"
                                        : "Обычный"}
                                </TableCell>
                                <TableCell>{node.status}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleUnlink(node._id)}
                                    >
                                        Unlink
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ExpiredNodes;
