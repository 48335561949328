import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Pagination,
    PaginationItem,
    TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment-timezone";

const MyPromosTable = ({ userId }) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [invoiceId, setInvoiceId] = useState("");
    const [totalInvoices, setTotalInvoices] = useState(0);
    const limit = 10;

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(
                `/admin/referral-invoices/${userId}`,
                {
                    params: { page, limit, invoice_id: invoiceId },
                }
            );
            setData(response.data.invoices);
            setTotalInvoices(response.data.total);
        } catch (error) {
            console.error("Error fetching invoices:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, invoiceId]);

    const totalPages = Math.ceil(totalInvoices / limit);

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h2 className="text-lg font-semibold">Referrals Invoices</h2>
            </AccordionSummary>
            <AccordionDetails>
                <TextField
                    value={invoiceId}
                    onChange={(e) => setInvoiceId(e.target.value)}
                    placeholder="Search by Invoice ID"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Invoice ID</TableCell>
                                <TableCell>User ID</TableCell>
                                <TableCell>Total Amount</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length ? (
                                data.map((row) => (
                                    <TableRow key={row._id}>
                                        <TableCell>{row._id}</TableCell>
                                        <TableCell>{row.user}</TableCell>
                                        <TableCell>
                                            ${row.totalPrice.toFixed(2)}
                                        </TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>
                                            {moment
                                                .tz(row.createdAt, "UTC")
                                                .format("DD.MM.YYYY HH:mm")}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={5}
                                        className="h-24 text-center"
                                    >
                                        No results.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {totalPages > 1 && (
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                        renderItem={(item) => (
                            <PaginationItem
                                {...item}
                                disabled={item.page === page}
                            />
                        )}
                    />
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default MyPromosTable;
