import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Typography,
} from "@mui/material";
import moment from "moment";

const InfluencerMonthlyStats = ({ userId }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(
                    `/admin/influencer-monthly-stats/${userId}`
                );

                const statsArray = Object.entries(
                    response.data.monthlyStats
                ).map(([key, value]) => ({
                    month: key,
                    ...value,
                }));

                setData(statsArray);
                setPercent(response.data.percent);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching stats:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [userId]);

    if (loading) {
        return <CircularProgress size={24} />;
    }

    return (
        <div className="mt-4">
            <Typography variant="h6" gutterBottom>
                Commission Percentage: {percent}%
            </Typography>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Month</TableCell>
                            <TableCell align="right">Total Volume</TableCell>
                            <TableCell align="right">Commission</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item) => (
                            <TableRow key={item.month}>
                                <TableCell>
                                    {moment(item.month, "YYYY-MM").format(
                                        "MMM YYYY"
                                    )}
                                </TableCell>
                                <TableCell align="right">
                                    ${item.monthlyAmount.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                    ${item.monthlyPaidAmount.toFixed(2)}
                                </TableCell>
                            </TableRow>
                        ))}

                        {data.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    No stats available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default InfluencerMonthlyStats;
