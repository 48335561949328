"use client";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosConfig";
import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Button,
    Snackbar,
    Alert,
    Typography,
} from "@mui/material";
import ReferralUsersTable from "./ReferalUsersTable";
import MonthlyStatsTable from "./ReferalMonthlyTable";
import ReferalProgramTable from "./ReferalProgramTable";
import { useParams } from "react-router-dom";

const ReferalProgram = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [promoStats, setPromoStats] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [user, setUser] = useState(null);
    const { userId } = useParams();

    const fetchUserInfo = async () => {
        try {
            const response = await axiosInstance.get(`/admin/user/${userId}`);
            setUser(response.data);
        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        const fetchPromoStats = async () => {
            try {
                const response = await axiosInstance.get(
                    `/admin/referral-stats/${userId}`
                );
                setPromoStats(response.data);
            } catch (error) {
                setError("Failed to fetch your promos");
            } finally {
                setLoading(false);
            }
        };
        fetchUserInfo();
        fetchPromoStats();
    }, []);

    if (loading) {
        return <CircularProgress className="w-16 h-16" />;
    }

    if (!promoStats) {
        return <div>Promo error</div>;
    }

    return (
        <div className="container mt-8 h-full relative">
            <div style={{ display: "flex", gap: 12, marginBottom: "30px" }}>
                <Card>
                    <CardContent className="flex items-center justify-between gap-2">
                        <Typography>Email: {user?.email}</Typography>
                        <Typography>Tg: {user?.tg}</Typography>
                        <Typography>
                            TgId: {user?.tgId}
                        </Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent className="flex items-center justify-between gap-2">
                        <Typography>Referral link</Typography>
                        <Typography className="sm:text-xl text-md sm:w-full w-[80%] break-words">
                            https://app.xnode.pro/auth/register?referalCode=
                            {user?.referalCode}
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ width: "200px" }}>
                    <CardContent>
                        <Typography>Balance</Typography>
                        <Typography className="text-4xl">
                            ${user?.balance.toFixed(2)}
                        </Typography>
                    </CardContent>
                </Card>
            </div>
            <div style={{ display: "flex", gap: 12, marginBottom: "30px" }}>
                <Card className="sm:w-[414px] w-full">
                    <CardContent className="mt-4">
                        <Typography mb={2}>
                            Referral Code: {user?.referalCode}
                        </Typography>
                        <Typography>
                            Refback Percent:{" "}
                            <span className="text-lg font-semibold text-white">
                                {promoStats.percent}%
                            </span>
                        </Typography>
                    </CardContent>
                </Card>
                <Card className="sm:w-[200px] w-full">
                    <CardContent className="mt-2">
                        <Typography>This Month Amount</Typography>
                        <Typography mb={2}>
                            ${promoStats.monthlyAmount.toFixed(2)}
                        </Typography>
                        <Typography>This Month Refback</Typography>
                        <Typography className="text-4xl">
                            ${promoStats.monthlyPaidAmount.toFixed(2)}
                        </Typography>
                    </CardContent>
                </Card>
                <Card className="sm:w-[200px] w-full">
                    <CardContent className="mt-2">
                        <Typography>Total Amount</Typography>
                        <Typography mb={2}>
                            ${promoStats.totalAmount.toFixed(2)}
                        </Typography>
                        <Typography>Total Refback</Typography>
                        <Typography className="text-4xl">
                            ${promoStats.totalPaidAmount.toFixed(2)}
                        </Typography>
                    </CardContent>
                </Card>
            </div>
            <MonthlyStatsTable userId={userId} />
            <ReferralUsersTable userId={userId} />
            <ReferalProgramTable userId={userId} />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity="success"
                >
                    The referral link is copied
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ReferalProgram;
