import React, { useState } from "react";
import {
    Button,
    TextField,
    Container,
    Typography,
    TextareaAutosize,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ethers } from "ethers";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import axios from "axios";
import FaucetForm from "./FaucetForm";
import axiosInstance from "../utils/axiosConfig";
import moment from "moment";

const SoftsPage = () => {
    const [walletCount, setWalletCount] = useState(0);
    const [addresses, setAddresses] = useState("");
    const [logs, setLogs] = useState("");
    const [fileName1, setFileName1] = useState("file1.txt");
    const [fileName2, setFileName2] = useState("file2.txt");
    const [splitAddresses, setSplitAddresses] = useState("");
    const [nodeName, setNodeName] = useState("");
    const [idList, setIdList] = useState("");
    const [generatedCommands, setGeneratedCommands] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const handleGenerate = () => {
        const wallets = [];
        for (let i = 0; i < walletCount; i++) {
            const wallet = ethers.Wallet.createRandom();
            wallets.push(wallet);
        }
        const data = wallets.map((wallet) => ({
            Address: wallet.address,
            "Private Key": wallet.privateKey,
            Mnemonic: wallet.mnemonic.phrase,
        }));
        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, "wallets.csv");
    };

    const handleCheckAddresses = async () => {
        const inputAddresses = addresses
            .split("\n")
            .map((line) => line.trim())
            .filter(Boolean);
        const offlineAddresses = [];
        let logMessages = "";

        for (const address of inputAddresses) {
            try {
                const response = await axios.get(
                    `https://api.prod.elixir.xyz/validators/${address}`
                );
                const validator = response.data.validator;
                const logMessage = `Adress: ${address}, name: ${
                    validator.display_name
                }, online: ${validator.online}, day uptime: ${(
                    validator.uptime_day * 100
                ).toFixed(2)}%, month uptime: ${(
                    validator.uptime_month * 100
                ).toFixed(2)}%`;
                logMessages += logMessage + "\n";
                setLogs(logMessages);

                if (!validator.online) {
                    offlineAddresses.push(address);
                }
                await new Promise((resolve) => setTimeout(resolve, 1000));
            } catch (error) {
                const errorMessage = `Ошибка при запросе к адресу ${address}: ${error.message}`;
                logMessages += errorMessage + "\n";
                setLogs(logMessages);
            }
        }

        if (offlineAddresses.length > 0) {
            const blob = new Blob([offlineAddresses.join("\n")], {
                type: "text/plain;charset=utf-8;",
            });
            saveAs(blob, "offline_addresses.txt");
        } else {
            console.log("No offline addresses found.");
        }
    };

    const handleSplitAddresses = () => {
        const inputAddresses = splitAddresses
            .split("\n")
            .map((line) => line.trim())
            .filter(Boolean);

        const oddAddresses = inputAddresses.filter(
            (_, index) => index % 2 !== 0
        );
        const evenAddresses = inputAddresses.filter(
            (_, index) => index % 2 === 0
        );

        const oddText = oddAddresses.join("\n");
        const evenText = evenAddresses.join("\n");

        const oddBlob = new Blob([oddText], {
            type: "text/plain;charset=utf-8;",
        });
        const evenBlob = new Blob([evenText], {
            type: "text/plain;charset=utf-8;",
        });

        saveAs(evenBlob, fileName1);
        saveAs(oddBlob, fileName2);
    };

    const handleFetchNodes = async () => {
        try {
            const response = await axiosInstance.get(
                `/admin/nodes/${nodeName}`
            );
            const nodes = response.data;
            const data = nodes.map((node) => ({
                _id: node._id,
                status: node.status,
                email: node.user.email || "",
                tg: node.user.tg || "",
            }));
            const csv = Papa.unparse(data, {
                delimiter: ";",
                header: true,
            });
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            saveAs(blob, `${nodeName}_nodes.csv`);
        } catch (error) {
            console.error("Error fetching nodes:", error);
        }
    };

    const handleGenerateCommands = () => {
        const ids = idList
            .split("\n")
            .map((line) => line.trim())
            .filter(Boolean);
        const commands = ids
            .map((id) => `echo ${id} > ~/.nexus/prover-id`)
            .join("\n");
        setGeneratedCommands(commands);
    };

    const handleFetchStats = async () => {
        try {
            const registrationsResponse = await axiosInstance.get(
                "/admin/dashboard-stats/registrations"
            );
            const nodeUsersResponse = await axiosInstance.get(
                "/admin/dashboard-stats/node-users"
            );

            const registrations = registrationsResponse.data;
            const nodeUsers = nodeUsersResponse.data;

            const filteredRegistrations = registrations.filter((reg) => {
                const regDate = new Date(reg._id);
                return (
                    regDate >= new Date(startDate) &&
                    regDate <= new Date(endDate)
                );
            });

            const filteredNodeUsers = nodeUsers.filter((node) => {
                const nodeDate = new Date(node._id);
                return (
                    nodeDate >= new Date(startDate) &&
                    nodeDate <= new Date(endDate)
                );
            });

            const data = filteredRegistrations.map((reg) => {
                const nodeUser = filteredNodeUsers.find(
                    (node) => node._id === reg._id
                ) || { count: 0 };
                return {
                    date: moment(reg._id).format("DD.MM.YYYY"),
                    registrations: reg.count,
                    nodes: nodeUser.count,
                };
            });

            const csv = Papa.unparse(data);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "stats.csv");
        } catch (error) {
            console.error("Error fetching stats:", error);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Softs
            </Typography>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                        Generate EVM Wallets CSV
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField
                        label="Number of Wallets"
                        type="number"
                        value={walletCount}
                        onChange={(e) => setWalletCount(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGenerate}
                    >
                        Generate
                    </Button>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Check Elixir Nodes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextareaAutosize
                        minRows={10}
                        placeholder="Enter addresses, one per line"
                        value={addresses}
                        onChange={(e) => setAddresses(e.target.value)}
                        style={{ width: "100%", marginBottom: "20px" }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCheckAddresses}
                    >
                        Check Addresses
                    </Button>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{ marginTop: "20px" }}
                    >
                        Logs
                    </Typography>
                    <TextareaAutosize
                        minRows={10}
                        value={logs}
                        readOnly
                        style={{ width: "100%" }}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                        Ethereum Testnets Faucet
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FaucetForm />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                        Split Addresses into Files
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextareaAutosize
                        minRows={10}
                        placeholder="Enter addresses, one per line"
                        value={splitAddresses}
                        onChange={(e) => setSplitAddresses(e.target.value)}
                        style={{ width: "100%", marginBottom: "20px" }}
                    />
                    <TextField
                        label="File Name for Even Addresses"
                        value={fileName1}
                        onChange={(e) => setFileName1(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="File Name for Odd Addresses"
                        value={fileName2}
                        onChange={(e) => setFileName2(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSplitAddresses}
                    >
                        Generate Files
                    </Button>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Fetch Node IDs by Name</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField
                        label="Node Name"
                        value={nodeName}
                        onChange={(e) => setNodeName(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFetchNodes}
                    >
                        Fetch Nodes and Download CSV
                    </Button>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                        Generate Nexus Commands
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextareaAutosize
                        minRows={10}
                        placeholder="Enter IDs, one per line"
                        value={idList}
                        onChange={(e) => setIdList(e.target.value)}
                        style={{ width: "100%", marginBottom: "20px" }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGenerateCommands}
                    >
                        Generate Commands
                    </Button>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{ marginTop: "20px" }}
                    >
                        Commands
                    </Typography>
                    <TextareaAutosize
                        minRows={10}
                        value={generatedCommands}
                        readOnly
                        style={{ width: "100%" }}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                        Fetch Stats and Download CSV
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField
                        label="Start Date"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFetchStats}
                    >
                        Fetch Stats and Download CSV
                    </Button>
                </AccordionDetails>
            </Accordion>
        </Container>
    );
};

export default SoftsPage;
