import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Autocomplete,
    Pagination,
    Typography,
} from "@mui/material";

const statuses = [
    "new",
    "pending",
    "pending internal",
    "mismatch",
    "completed",
    "error",
    "expired",
    "cancelled",
    "paid",
    "paid_over",
    "wrong_amount",
    "process",
    "confirm_check",
    "wrong_amount_waiting",
    "check",
    "fail",
    "cancel",
    "system_fail",
    "locked",
];

const InvoiceTable = () => {
    const [invoices, setInvoices] = useState([]);
    const [filters, setFilters] = useState({
        invoice_id: "",
        user_email: "",
        status: [],
        dateFrom: "",
        dateTo: "",
        tg: "",
        tgId: "",
    });
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchInvoices();
    }, [filters, page]);

    const fetchInvoices = async () => {
        try {
            const response = await axiosInstance.get("/admin/invoices", {
                params: { ...filters, page },
            });
            setInvoices(response.data.invoices);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error("Error fetching invoices:", error);
        }
    };

    const handleChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    const handleStatusChange = (event, value) => {
        setFilters({
            ...filters,
            status: value.map((v) => v),
        });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <div>
            <Typography sx={{ marginBottom: "20px", fontWeight: "700" }}>
                Invoices
            </Typography>
            <div style={{ padding: "16px", display: "flex", flexWrap: "wrap" }}>
                <TextField
                    label="Invoice ID"
                    name="invoice_id"
                    value={filters.invoice_id}
                    onChange={handleChange}
                    style={{ marginRight: "16px" }}
                />
                <TextField
                    label="User Email"
                    name="user_email"
                    value={filters.user_email}
                    onChange={handleChange}
                    style={{ marginRight: "16px" }}
                />
                <TextField
                    label="TG"
                    name="tg"
                    value={filters.tg}
                    onChange={handleChange}
                    style={{ marginRight: "16px" }}
                />
                <TextField
                    label="TG ID"
                    name="tgId"
                    value={filters.tgId}
                    onChange={handleChange}
                    style={{ marginRight: "16px" }}
                />
                <Autocomplete
                    multiple
                    options={statuses}
                    getOptionLabel={(option) => option}
                    onChange={handleStatusChange}
                    style={{ marginRight: "16px", width: "300px" }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Status"
                            variant="outlined"
                            style={{ marginRight: "16px" }}
                        />
                    )}
                />
                <TextField
                    label="Date From"
                    name="dateFrom"
                    type="date"
                    value={filters.dateFrom}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: "16px" }}
                />
                <TextField
                    label="Date To"
                    name="dateTo"
                    type="date"
                    value={filters.dateTo}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: "16px" }}
                />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Invoice ID</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>User ID</TableCell>
                            <TableCell>TG ID</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Total Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map((invoice) => (
                            <TableRow key={invoice._id}>
                                <TableCell>{invoice.invoice_id}</TableCell>
                                <TableCell>
                                    {invoice.user.email ||
                                        `@${invoice.user.tg}`}
                                </TableCell>
                                <TableCell>{invoice.user._id}</TableCell>
                                <TableCell>{invoice.user.tgId}</TableCell>
                                <TableCell>{invoice.status}</TableCell>
                                <TableCell>
                                    {new Date(
                                        invoice.createdAt
                                    ).toLocaleDateString()}
                                </TableCell>
                                <TableCell>{invoice.totalPrice}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                sx={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                }}
            />
        </div>
    );
};

export default InvoiceTable;
