import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosConfig";
import { useParams } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Pagination,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const UserNodes = () => {
    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [nodes, setNodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState("");
    const [id, setId] = useState("");
    const [status, setStatus] = useState("");
    const [deployType, setDeployType] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [open, setOpen] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);
    const [editData, setEditData] = useState({});
    const [newNodeData, setNewNodeData] = useState({
        name: "",
        image: "",
        installation_price: "",
        price_per_month: "",
        link: "",
        date: "",
        endDate: "",
        deployType: "",
        status: "",
        promoCode: "",
        is_reneweble: true,
    });

    const [keys, setKeys] = useState("");

    const fetchUserInfo = async () => {
        try {
            const response = await axiosInstance.get(`/admin/user/${userId}`);
            setUser(response.data);
        } catch (err) {
            setError(err);
        }
    };

    const fetchUserNodes = async () => {
        try {
            const response = await axiosInstance.get(
                `/admin/user-nodes/${userId}`,
                {
                    params: {
                        search,
                        id,
                        status,
                        deployType,
                        startDate: startDate ? startDate.toISOString() : "",
                        endDate: endDate ? endDate.toISOString() : "",
                        page,
                    },
                }
            );
            setNodes(response.data.nodes);
            setTotalPages(response.data.pages);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (node) => {
        setSelectedNode(node);
        setEditData(node);
        setKeys(node.keys);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedNode(null);
        setKeys("");
    };

    const handleSave = async () => {
        try {
            await axiosInstance.put(
                `/admin/edit-user-node/${selectedNode._id}`,
                { ...editData, keys }
            );
            fetchUserNodes();
            handleClose();
        } catch (error) {
            console.error("Error saving node:", error);
        }
    };

    const handleCreate = async () => {
        try {
            await axiosInstance.post(
                `/admin/user-nodes/${userId}`,
                {...newNodeData, keys}
            );
            fetchUserNodes();
            setNewNodeData({
                name: "",
                image: "",
                installation_price: "",
                price_per_month: "",
                link: "",
                date: "",
                endDate: "",
                deployType: "",
                status: "",
                promoCode: "",
                is_reneweble: true,
            });
            handleClose();
        } catch (error) {
            console.error("Error creating node:", error);
        }
    };

    useEffect(() => {
        fetchUserInfo();
        fetchUserNodes();
    }, [search, id, status, deployType, startDate, endDate, page, userId]);

    const handleQuillChange = (setter) => (value) => {
        setter(value);
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">Ошибка: {error.message}</Typography>;
    }

    return (
        <div>
            <Typography sx={{ marginBottom: "20px", fontWeight: "700" }}>
                User Nodes
            </Typography>
            {user && (
                <Typography sx={{ marginBottom: "20px" }}>
                    Email или TG: {user.email || user.tg}, id: {user._id}
                </Typography>
            )}
            <div
                style={{
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <TextField
                    label="Поиск по id"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                    style={{ marginRight: "10px" }}
                />
                <TextField
                    label="Поиск по названию"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ marginRight: "10px" }}
                />
                <FormControl style={{ marginRight: "10px", minWidth: 120 }}>
                    <InputLabel>Статус</InputLabel>
                    <Select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>Все</em>
                        </MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="installed">Installed</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ marginRight: "10px", minWidth: 120 }}>
                    <InputLabel>Тип установки</InputLabel>
                    <Select
                        value={deployType}
                        onChange={(e) => setDeployType(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>Все</em>
                        </MenuItem>
                        <MenuItem value="new">New</MenuItem>
                        <MenuItem value="keys">With keys</MenuItem>
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Дата начала"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ marginRight: "10px" }}
                            />
                        )}
                    />
                    <DatePicker
                        label="Дата окончания"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                style={{ marginRight: "10px" }}
                            />
                        )}
                    />
                </LocalizationProvider>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                    style={{ marginLeft: "20px" }}
                >
                    Создать новую ноду
                </Button>
            </div>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Имя</TableCell>
                            <TableCell>Изображение</TableCell>
                            <TableCell>Цена установки</TableCell>
                            <TableCell>Цена за месяц</TableCell>
                            <TableCell>Ссылка</TableCell>
                            <TableCell>Дата установки</TableCell>
                            <TableCell>Дата окончания</TableCell>
                            <TableCell>Тип деплоя</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nodes.map((node) => (
                            <TableRow key={node._id}>
                                <TableCell>{node._id}</TableCell>
                                <TableCell>{node.name}</TableCell>
                                <TableCell>
                                    <img
                                        src={node.image}
                                        alt={node.name}
                                        width="50"
                                    />
                                </TableCell>
                                <TableCell>{node.installation_price}</TableCell>
                                <TableCell>{node.price_per_month}</TableCell>
                                <TableCell>
                                    <a
                                        href={node.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {node.link}
                                    </a>
                                </TableCell>
                                <TableCell>
                                    {new Date(node.date).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    {new Date(
                                        node.endDate
                                    ).toLocaleDateString()}
                                </TableCell>
                                <TableCell>{node.deployType}</TableCell>
                                <TableCell>{node.status}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleEditClick(node)}
                                    >
                                        Редактировать
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
                style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                }}
            />

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {selectedNode ? "Редактировать ноду" : "Создать новую ноду"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Внесите изменения и нажмите "Сохранить".
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Имя"
                        fullWidth
                        value={selectedNode ? editData.name : newNodeData.name}
                        onChange={(e) =>
                            selectedNode
                                ? setEditData({
                                      ...editData,
                                      name: e.target.value,
                                  })
                                : setNewNodeData({
                                      ...newNodeData,
                                      name: e.target.value,
                                  })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Изображение"
                        fullWidth
                        value={
                            selectedNode ? editData.image : newNodeData.image
                        }
                        onChange={(e) =>
                            selectedNode
                                ? setEditData({
                                      ...editData,
                                      image: e.target.value,
                                  })
                                : setNewNodeData({
                                      ...newNodeData,
                                      image: e.target.value,
                                  })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Цена установки"
                        fullWidth
                        value={
                            selectedNode
                                ? editData.installation_price
                                : newNodeData.installation_price
                        }
                        onChange={(e) =>
                            selectedNode
                                ? setEditData({
                                      ...editData,
                                      installation_price: e.target.value,
                                  })
                                : setNewNodeData({
                                      ...newNodeData,
                                      installation_price: e.target.value,
                                  })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Цена за месяц"
                        fullWidth
                        value={
                            selectedNode
                                ? editData.price_per_month
                                : newNodeData.price_per_month
                        }
                        onChange={(e) =>
                            selectedNode
                                ? setEditData({
                                      ...editData,
                                      price_per_month: e.target.value,
                                  })
                                : setNewNodeData({
                                      ...newNodeData,
                                      price_per_month: e.target.value,
                                  })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Ссылка"
                        fullWidth
                        value={selectedNode ? editData.link : newNodeData.link}
                        onChange={(e) =>
                            selectedNode
                                ? setEditData({
                                      ...editData,
                                      link: e.target.value,
                                  })
                                : setNewNodeData({
                                      ...newNodeData,
                                      link: e.target.value,
                                  })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Дата установки"
                        fullWidth
                        type="date"
                        value={
                            selectedNode
                                ? editData.date
                                    ? new Date(editData.date)
                                          .toISOString()
                                          .substr(0, 10)
                                    : ""
                                : newNodeData.date
                                ? new Date(newNodeData.date)
                                      .toISOString()
                                      .substr(0, 10)
                                : ""
                        }
                        onChange={(e) =>
                            selectedNode
                                ? setEditData({
                                      ...editData,
                                      date: new Date(e.target.value),
                                  })
                                : setNewNodeData({
                                      ...newNodeData,
                                      date: new Date(e.target.value),
                                  })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Дата окончания"
                        fullWidth
                        type="date"
                        value={
                            selectedNode
                                ? editData.endDate
                                    ? new Date(editData.endDate)
                                          .toISOString()
                                          .substr(0, 10)
                                    : ""
                                : newNodeData.endDate
                                ? new Date(newNodeData.endDate)
                                      .toISOString()
                                      .substr(0, 10)
                                : ""
                        }
                        onChange={(e) =>
                            selectedNode
                                ? setEditData({
                                      ...editData,
                                      endDate: new Date(e.target.value),
                                  })
                                : setNewNodeData({
                                      ...newNodeData,
                                      endDate: new Date(e.target.value),
                                  })
                        }
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Тип деплоя</InputLabel>
                        <Select
                            value={
                                selectedNode
                                    ? editData.deployType
                                    : newNodeData.deployType
                            }
                            onChange={(e) =>
                                selectedNode
                                    ? setEditData({
                                          ...editData,
                                          deployType: e.target.value,
                                      })
                                    : setNewNodeData({
                                          ...newNodeData,
                                          deployType: e.target.value,
                                      })
                            }
                        >
                            <MenuItem value="new">New</MenuItem>
                            <MenuItem value="keys">With keys</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Статус</InputLabel>
                        <Select
                            value={
                                selectedNode
                                    ? editData.status
                                    : newNodeData.status
                            }
                            onChange={(e) =>
                                selectedNode
                                    ? setEditData({
                                          ...editData,
                                          status: e.target.value,
                                      })
                                    : setNewNodeData({
                                          ...newNodeData,
                                          status: e.target.value,
                                      })
                            }
                        >
                            <MenuItem value="pending">Pending</MenuItem>
                            <MenuItem value="installed">Installed</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Промо-код"
                        fullWidth
                        value={
                            selectedNode
                                ? editData.promoCode
                                : newNodeData.promoCode
                        }
                        onChange={(e) =>
                            selectedNode
                                ? setEditData({
                                      ...editData,
                                      promoCode: e.target.value,
                                  })
                                : setNewNodeData({
                                      ...newNodeData,
                                      promoCode: e.target.value,
                                  })
                        }
                    />
                    <ReactQuill
                        placeholder="Ключи"
                        value={keys}
                        onChange={handleQuillChange(setKeys)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    selectedNode
                                        ? editData.is_reneweble
                                        : newNodeData.is_reneweble
                                }
                                onChange={(e) =>
                                    selectedNode
                                        ? setEditData({
                                              ...editData,
                                              is_reneweble: e.target.checked,
                                          })
                                        : setNewNodeData({
                                              ...newNodeData,
                                              is_reneweble: e.target.checked,
                                          })
                                }
                            />
                        }
                        label="Is Reneweble"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button onClick={selectedNode ? handleSave : handleCreate}>
                        {selectedNode ? "Сохранить" : "Создать"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UserNodes;
