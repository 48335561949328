import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Pagination,
    PaginationItem,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment-timezone";


const ReferralUsersTable = ({ userId }) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userNodes, setUserNodes] = useState([]);
    const [nodePage, setNodePage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const limit = 10;
    const nodeLimit = 3;

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(
                `/admin/referral-users/${userId}`,
                {
                    params: { page, limit },
                }
            );
            setData(response.data.referredUsers);
            setTotalUsers(response.data.total);
        } catch (error) {
            console.error("Error fetching referred users:", error);
        }
    };

    const fetchUserDetails = async (userId) => {
        try {
            const response = await axiosInstance.get(`/promos/users/${userId}`);
            setSelectedUser(response.data.user);
            setUserNodes(response.data.nodes);
            setNodePage(1);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page]);

    const totalPages = Math.ceil(totalUsers / limit);
    const totalNodePages = Math.ceil(userNodes.length / nodeLimit);

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h2 className="text-lg font-semibold">Referral Users</h2>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>User ID</TableCell>
                                <TableCell>Email or TG</TableCell>
                                <TableCell>Registration Date</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length ? (
                                data.map((row) => (
                                    <TableRow key={row._id}>
                                        <TableCell>{row._id}</TableCell>
                                        <TableCell>
                                            {row.email
                                                ? row.email
                                                : row.tg}
                                        </TableCell>
                                        <TableCell>
                                            {moment
                                                .tz(
                                                    row.registration_date,
                                                    "UTC"
                                                )
                                                .format("DD.MM.YYYY HH:mm")}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={() =>
                                                    fetchUserDetails(row._id)
                                                }
                                            >
                                                User Info
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={4}
                                        className="h-24 text-center"
                                    >
                                        You don't have any referrals yet.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {totalPages > 1 && (
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                        renderItem={(item) => (
                            <PaginationItem
                                {...item}
                                disabled={item.page === page}
                            />
                        )}
                    />
                )}
            </AccordionDetails>
            {selectedUser && (
                <Dialog
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                >
                    <DialogTitle>User Details</DialogTitle>
                    <DialogContent>
                        <p>
                            <strong>User ID:</strong> {selectedUser._id}
                        </p>
                        <p>
                            <strong>Email or TG:</strong>{" "}
                            {selectedUser.email
                                ? selectedUser.email
                                : selectedUser.tg}
                        </p>
                        <p>
                            <strong>Registration Date:</strong>{" "}
                            {moment
                                .tz(selectedUser.registration_date, "UTC")
                                .format("DD.MM.YYYY HH:mm")}
                        </p>
                        <h3>Nodes</h3>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Installation</TableCell>
                                        <TableCell>Per Month</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userNodes.length === 0 ? (
                                        <TableRow>
                                            <TableCell
                                                colSpan={4}
                                                className="h-24 text-center"
                                            >
                                                This user doesn't have any nodes
                                                yet.
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        userNodes
                                            .slice(
                                                (nodePage - 1) * nodeLimit,
                                                nodePage * nodeLimit
                                            )
                                            .map((node) => (
                                                <TableRow key={node._id}>
                                                    <TableCell>
                                                        {node.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {node.status}
                                                    </TableCell>
                                                    <TableCell>
                                                        $
                                                        {node.installation_price.toFixed(
                                                            2
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        $
                                                        {node.price_per_month.toFixed(
                                                            2
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {totalNodePages > 1 && (
                            <Pagination
                                count={totalNodePages}
                                page={nodePage}
                                onChange={(event, value) => setNodePage(value)}
                                renderItem={(item) => (
                                    <PaginationItem
                                        {...item}
                                        disabled={item.page === nodePage}
                                    />
                                )}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsModalOpen(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Accordion>
    );
};

export default ReferralUsersTable;
