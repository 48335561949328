import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
    FormControlLabel,
} from "@mui/material";

const Tasks = () => {
    const [tasks, setTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [editData, setEditData] = useState({});
    const [newTaskData, setNewTaskData] = useState({
        name: "",
        description: "",
        points: 0,
        isActive: false,
        target: 1,
    });
    const [open, setOpen] = useState(false);

    const getCellStyle = (value) => {
        return {
            color: value === "Yes" ? "green" : "red",
        };
    };

    const fetchAllQuests = async () => {
        const response = await axiosInstance.get("/quests/");
        setTasks(response.data);
    };
    useEffect(() => {
        fetchAllQuests();
    }, []);

    const handleEditClick = (task) => {
        setSelectedTask(task);
        setEditData(task);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedTask(null);
    };

    const handleSave = async () => {
        try {
            await axiosInstance.put(
                `/quests/edit/${selectedTask._id}`,
                editData
            );
            fetchAllQuests();
            handleClose();
        } catch (error) {
            console.error("Error saving task:", error);
        }
    };

    const handleCreate = async () => {
        try {
            await axiosInstance.post(`/quests/create`, newTaskData);
            fetchAllQuests();
            setNewTaskData({
                name: "",
                description: "",
                points: 0,
                isActive: false,
                target: 1,
            });
            handleClose();
        } catch (error) {
            console.error("Error creating node:", error);
        }
    };

    console.log("open", open);

    return (
        <div>
            <Typography sx={{ marginBottom: "20px", fontWeight: "700" }}>
                Tasks
            </Typography>
            <div
                style={{
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                    style={{ marginLeft: "20px" }}
                >
                    Создать
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Points</TableCell>
                            <TableCell>Target</TableCell>
                            <TableCell>Is Active</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tasks.map((task) => (
                            <TableRow key={task._id}>
                                <TableCell>{task._id}</TableCell>
                                <TableCell>{task.name}</TableCell>
                                <TableCell>{task.description}</TableCell>
                                <TableCell>{task.points}</TableCell>
                                <TableCell>{task.target}</TableCell>
                                <TableCell
                                    style={getCellStyle(
                                        task.isActive ? "Yes" : "No"
                                    )}
                                >
                                    {task.isActive ? "Yes" : "No"}
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleEditClick(task)}
                                    >
                                        Редактировать
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {selectedTask ? "Редактировать ноду" : "Создать новую ноду"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Внесите изменения и нажмите "Сохранить".
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Имя"
                        fullWidth
                        value={selectedTask ? editData.name : newTaskData.name}
                        onChange={(e) =>
                            selectedTask
                                ? setEditData({
                                      ...editData,
                                      name: e.target.value,
                                  })
                                : setNewTaskData({
                                      ...newTaskData,
                                      name: e.target.value,
                                  })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Описание"
                        fullWidth
                        value={
                            selectedTask
                                ? editData.description
                                : newTaskData.description
                        }
                        onChange={(e) =>
                            selectedTask
                                ? setEditData({
                                      ...editData,
                                      description: e.target.value,
                                  })
                                : setNewTaskData({
                                      ...newTaskData,
                                      description: e.target.value,
                                  })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Количество поинтов"
                        fullWidth
                        value={
                            selectedTask ? editData.points : newTaskData.points
                        }
                        onChange={(e) =>
                            selectedTask
                                ? setEditData({
                                      ...editData,
                                      points: e.target.value,
                                  })
                                : setNewTaskData({
                                      ...newTaskData,
                                      points: e.target.value,
                                  })
                        }
                    />
                    <TextField
                        margin="dense"
                        label="Цель"
                        fullWidth
                        value={
                            selectedTask ? editData.target : newTaskData.target
                        }
                        onChange={(e) =>
                            selectedTask
                                ? setEditData({
                                      ...editData,
                                      target: e.target.value,
                                  })
                                : setNewTaskData({
                                      ...newTaskData,
                                      target: e.target.value,
                                  })
                        }
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    selectedTask
                                        ? editData.isActive
                                        : newTaskData.isActive
                                }
                                onChange={(e) =>
                                    selectedTask
                                        ? setEditData({
                                              ...editData,
                                              isActive: e.target.checked,
                                          })
                                        : setNewTaskData({
                                              ...newTaskData,
                                              isActive: e.target.checked,
                                          })
                                }
                            />
                        }
                        label="Is Active"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button onClick={selectedTask ? handleSave : handleCreate}>
                        {selectedTask ? "Сохранить" : "Создать"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Tasks;
