import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    Button,
    Modal,
    Box,
    TextField,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const PendingNodes = () => {
    const [nodes, setNodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);
    const [search, setSearch] = useState("");
    const [user, setUser] = useState("");
    const [id, setId] = useState("");
    const [editData, setEditData] = useState({});
    const [keys, setKeys] = useState("");

    const fetchPendingNodes = async () => {
        try {
            const response = await axiosInstance.get("/admin/pending-nodes", {
                params: {
                    id,
                    search,
                    user,
                },
            });
            setNodes(response.data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchPendingNodes();
    }, [id, search, user]);

    const handleOpen = (node) => {
        setSelectedNode(node);
        setEditData(node);
        setKeys(node.keys);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedNode(null);
        setKeys("");
    };

    const handleUpdate = async () => {
        try {
            await axiosInstance.put(
                `/admin/update-user-node/${selectedNode._id}`,
                {
                    link: editData.link,
                    keys: keys,
                    endDate: editData.endDate,
                    name: editData.name,
                    userId: editData.user._id,
                }
            );

            fetchPendingNodes();

            handleClose();
        } catch (err) {
            console.error("Error updating node:", err);
        }
    };

    const handleQuillChange = (setter) => (value) => {
        setter(value);
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">Ошибка: {error.message}</Typography>;
    }

    return (
        <div>
            <Typography sx={{ marginBottom: "20px", fontWeight: "700" }}>
                Pending User Nodes
            </Typography>
            <div style={{ marginBottom: "15px" }}>
                <TextField
                    label="Поиск по id"
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                    style={{ marginRight: "10px" }}
                />
                <TextField
                    label="Поиск по названию"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ marginRight: "10px" }}
                />
                <TextField
                    label="Поиск по пользователю"
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    style={{ marginRight: "10px" }}
                />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Имя</TableCell>
                            <TableCell>Изображение</TableCell>
                            <TableCell>Email или TG пользователя</TableCell>
                            <TableCell>ID пользователя</TableCell>
                            <TableCell>Referer Email</TableCell>
                            <TableCell>Дата установки</TableCell>
                            <TableCell>Дата окончания</TableCell>
                            <TableCell>Тип деплоя</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nodes.map((node) => (
                            <TableRow key={node._id}>
                                <TableCell>{node._id}</TableCell>
                                <TableCell>{node.name}</TableCell>
                                <TableCell>
                                    <img
                                        src={node.image}
                                        alt={node.name}
                                        width="50"
                                    />
                                </TableCell>
                                <TableCell>
                                    {node.user.email || `@${node.user.tg}`}
                                </TableCell>
                                <TableCell>{node.user._id}</TableCell>
                                <TableCell>
                                    {node.user.refererId
                                        ? node.user.refererId.email ||
                                          node.user.refererId.tg
                                        : "-"}
                                </TableCell>
                                <TableCell>
                                    {new Date(node.date).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    {new Date(
                                        node.endDate
                                    ).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    {node.deployType === "keys"
                                        ? "С ключами"
                                        : "Обычный"}
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleOpen(node)}
                                    >
                                        Обновить
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" component="h2">
                        Обновить
                    </Typography>
                    <TextField
                        fullWidth
                        label="Ссылка"
                        value={editData.link}
                        onChange={(e) =>
                            setEditData({ ...editData, link: e.target.value })
                        }
                        margin="normal"
                    />
                    <ReactQuill
                        placeholder="Ключи"
                        value={keys}
                        onChange={handleQuillChange(setKeys)}
                    />
                    <TextField
                        margin="dense"
                        label="Дата окончания"
                        fullWidth
                        type="date"
                        value={
                            editData.endDate &&
                            new Date(editData.endDate)
                                .toISOString()
                                .substr(0, 10)
                        }
                        onChange={(e) =>
                            setEditData({
                                ...editData,
                                endDate: new Date(e.target.value),
                            })
                        }
                    />
                    <Button variant="contained" onClick={handleUpdate}>
                        Обновить
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default PendingNodes;
