// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.ql-container {
    background-color: #121212;
    color: #ffffff;
}

.ql-editor {
    background-color: #121212;
    color: #ffffff;
}

.ql-editor::placeholder {
    color: #ffffff;
    opacity: 0.6;
}

.ql-toolbar {
    background-color: #1d1d1d;
    border: 1px solid #444444;
}

.ql-toolbar .ql-picker-label,
.ql-toolbar .ql-picker-item {
    color: #ffffff;
}

.ql-toolbar .ql-stroke {
    stroke: #ffffff;
}

.ql-toolbar .ql-fill {
    fill: #ffffff;
}
.ql-editor.ql-blank::before {
    color: #ffffff !important;
    opacity: 0.6;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT;;oCAEgC;IAChC,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI;iBACa;AACjB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;;IAEI,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;AACA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":["body {\n    margin: 0;\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\",\n        \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\",\n        \"Helvetica Neue\", sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n        monospace;\n}\n\n.ql-container {\n    background-color: #121212;\n    color: #ffffff;\n}\n\n.ql-editor {\n    background-color: #121212;\n    color: #ffffff;\n}\n\n.ql-editor::placeholder {\n    color: #ffffff;\n    opacity: 0.6;\n}\n\n.ql-toolbar {\n    background-color: #1d1d1d;\n    border: 1px solid #444444;\n}\n\n.ql-toolbar .ql-picker-label,\n.ql-toolbar .ql-picker-item {\n    color: #ffffff;\n}\n\n.ql-toolbar .ql-stroke {\n    stroke: #ffffff;\n}\n\n.ql-toolbar .ql-fill {\n    fill: #ffffff;\n}\n.ql-editor.ql-blank::before {\n    color: #ffffff !important;\n    opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
