import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosConfig";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Checkbox,
    Button,
    FormControlLabel,
    Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const WarningSchema = Yup.object().shape({
    isEnabled: Yup.boolean(),
    text: Yup.string().required("Required"),
});

const WarningSettings = () => {
    const [initialValues, setInitialValues] = useState({
        isEnabled: false,
        text: "",
    });

    useEffect(() => {
        const fetchWarning = async () => {
            const response = await axiosInstance.get("/auth/warning");
            if (response.data) {
                setInitialValues({
                    isEnabled: response.data.isEnabled,
                    text: response.data.text,
                });
            }
        };
        fetchWarning();
    }, []);

    const handleSubmit = async (values) => {
        await axiosInstance.post("/admin/warning", values);
    };

    return (
        <div>
            <Typography sx={{ marginBottom: "20px", fontWeight: "700" }}>
                Warning
            </Typography>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={WarningSchema}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, setFieldValue }) => (
                    <Form>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isEnabled"
                                    checked={values.isEnabled}
                                    onChange={handleChange}
                                />
                            }
                            label="Enable Warning"
                        />
                        <Field name="text">
                            {({ field }) => (
                                <ReactQuill
                                    value={field.value}
                                    onChange={(value) =>
                                        setFieldValue("text", value)
                                    }
                                    placeholder="Text"
                                />
                            )}
                        </Field>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{marginTop: "20px"}}
                        >
                            Save
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default WarningSettings;