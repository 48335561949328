import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Typography,
    TablePagination,
} from "@mui/material";

const Uptime = () => {
    const [nodes, setNodes] = useState([]);
    const [mainNodeId, setMainNodeId] = useState("");
    const [email, setEmail] = useState("");
    const [link, setLink] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalNodes, setTotalNodes] = useState(0);

    const fetchData = async (page = 0, rowsPerPage = 10) => {
        try {
            const response = await axiosInstance.get("/admin/uptime-nodes", {
                params: {
                    mainNodeId,
                    email,
                    link,
                    page: page + 1,
                    limit: rowsPerPage,
                },
            });
            setNodes(response.data.nodes);
            setTotalNodes(response.data.totalNodes);
        } catch (error) {
            console.error("Error fetching uptime nodes:", error);
        }
    };

    useEffect(() => {
        fetchData(page, rowsPerPage);
    }, [page, rowsPerPage, email, link, mainNodeId]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <Typography sx={{ marginBottom: "20px", fontWeight: "700" }}>
                Uptime
            </Typography>
            <div style={{ marginBottom: "20px", display: "flex", gap: "20px" }}>
                <TextField
                    label="Main Node ID"
                    value={mainNodeId}
                    onChange={(e) => setMainNodeId(e.target.value)}
                />
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="Link"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Link</TableCell>
                            <TableCell>User Email</TableCell>
                            <TableCell>Last Checked</TableCell>
                            <TableCell>Last History Status</TableCell>
                            <TableCell>Last History Response Time</TableCell>
                            <TableCell>Last History Timestamp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nodes.map((node) => (
                            <TableRow key={node._id}>
                                <TableCell>{node._id}</TableCell>
                                <TableCell>{node.name}</TableCell>
                                <TableCell>{node.link}</TableCell>
                                <TableCell>{node.user.email}</TableCell>
                                <TableCell>
                                    {new Date(
                                        node.uptime.lastChecked
                                    ).toLocaleString()}
                                </TableCell>
                                <TableCell
                                    style={{
                                        color:
                                            node.uptime.history?.status ===
                                            "down"
                                                ? "red"
                                                : "#2EE11A",
                                    }}
                                >
                                    {node.uptime.history
                                        ? node.uptime.history[
                                              node.uptime.history.length - 1
                                          ].status
                                        : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {node.uptime.history
                                        ? node.uptime.history[
                                              node.uptime.history.length - 1
                                          ].responseTime
                                        : "N/A"}
                                </TableCell>
                                <TableCell>
                                    {node.uptime.history
                                        ? new Date(
                                              node.uptime.history[
                                                  node.uptime.history.length - 1
                                              ].timestamp
                                          ).toLocaleString()
                                        : "N/A"}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={totalNodes}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    );
};

export default Uptime;
